/*
* Folha de estilos da tela de Login
*/

/*
* Container dos itens da tela de login, esta classe
* utiliza flex-box para alinhar os itens.
*/

.login-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  flex-direction: row;
  justify-content: space-around;
  max-height: 100vh;
  overflow: hidden;
  background: url(/static/media/login-bg.1297f4ec.png);
  background-size: cover;
  background-position: left;
}

/*
* Imagem que fica do lado esquerdo da tela de login
*/

.login__logo {
  width: 500px;
  height: 200px;
  background: url(/static/media/logo-branca.7f3cf130.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
}

.login__panel {
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

/*
* Container onde ficam os input fields da
* tela de login.
*/

.login__content {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px 0 60px;
  background-color: white;
}

.login__email {
  margin-bottom: 30px !important;
}

.login__button {
  width: 100px;
  align-self: center;
  margin-top: 20px !important;
}

.login__logo__mobile {
  display: none;
}

@media (max-width: 768px) {
  .login__panel {
    display: none;
  }

  .login__logo__mobile {
    display: inline;
    display: initial;
    margin: 10px auto;
    width: 300px;
    height: 100px;
    background: url(/static/media/midia-logo.2c6fab7c.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .login__content {
    padding: 10px;
    width: auto;
    background-color: transparent;
  }

  .login__content div::before,
  .login__content div::after {
    border-color: white !important;
  }

  .login__content label,
  .login__content svg,
  .login__content input {
    color: white !important;
  }

  .login__content button {
    background-color: white;
    color: yellowgreen;
  }
}

/*
* Folha de estilos da tela de Login
*/

/*
* Container dos itens da tela de login, esta classe
* utiliza flex-box para alinhar os itens.
*/

.login-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  flex-direction: row;
  justify-content: space-around;
  max-height: 100vh;
  overflow: hidden;
  background: url(/static/media/login-bg.1297f4ec.png);
  background-size: cover;
  background-position: left;
}

/*
* Imagem que fica do lado esquerdo da tela de login
*/

.login__logo {
  width: 500px;
  height: 200px;
  background: url(/static/media/logo-branca.7f3cf130.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
}

.login__panel {
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

/*
* Container onde ficam os input fields da
* tela de login.
*/

.login__content {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px 0 60px;
  background-color: white;
}

.login__email {
  margin-bottom: 30px !important;
}

.login__button {
  width: 100px;
  align-self: center;
  margin-top: 20px !important;
}

.login__logo__mobile {
  display: none;
}

@media (max-width: 768px) {
  .login__panel {
    display: none;
  }

  .login__logo__mobile {
    display: inline;
    display: initial;
    margin: 10px auto;
    width: 300px;
    height: 100px;
    background: url(/static/media/logo-branca.7f3cf130.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .login__content {
    padding: 10px;
    width: auto;
    background-color: transparent;
  }

  .login__content div::before,
  .login__content div::after {
    border-color: white !important;
  }

  .login__content label,
  .login__content svg,
  .login__content input {
    color: white !important;
  }

  .login__content button {
    background-color: white;
    color: yellowgreen;
  }
}

.painel-title {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  margin-top: -60px;
}

.painel-subtitle {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-bottom: 50px;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
menu,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html {
  font-family: arial;
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section,
details,
figcaption {
  display: block;
}

html.mobile {
  overflow-y: auto;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: 700;
  vertical-align: bottom;
}

td {
  font-weight: 400;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
}

input[type='radio'] {
  vertical-align: text-bottom;
}

input[type='checkbox'] {
  vertical-align: bottom;
  *vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select,
input,
textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

a:hover,
a:active {
  outline: none;
}

small {
  font-size: 85%;
}

strong,
th {
  font-weight: 700;
}

td,
td img {
  vertical-align: top;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

.clickable,
label,
input[type='button'],
input[type='submit'],
button {
  cursor: pointer;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

button {
  width: auto;
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  margin: 0;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type='search'] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

.ie7 img {
  -ms-interpolation-mode: bicubic;
}

.auto-clear:before,
.grid:before,
.auto-clear:after,
.grid:after {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
}

.auto-clear:after,
.grid:after {
  clear: both;
  margin-bottom: 20px;
}

.auto-clear,
.grid {
  zoom: 1;
}

.clear {
  clear: both;
}

html.mobile {
  -webkit-text-size-adjust: none;
}

html.mobile * {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html.mobile *:not(input, select) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html.mobile html,
html.mobile body,
html.mobile form,
html.mobile fieldset,
html.mobile p,
html.mobile div,
html.mobile h1,
html.mobile h2,
html.mobile h3,
html.mobile h4,
html.mobile h5,
html.mobile h6 {
  -webkit-text-size-adjust: none;
}

.responsive {
  width: 100%;
  max-height: 1000px;
  line-height: 1;
  display: block;
}

html.desktop .only-ios,
html.android .only-ios {
  display: none;
}

html.desktop .only-android,
html.ios .only-android {
  display: none;
}

html.desktop .only-mobile {
  display: none;
}

html.mobile .only-desktop {
  display: none;
}

.no-bottom-margin,
.no-bottom-margin:after {
  margin-bottom: 0 !important;
}

.element-spacing {
  margin-bottom: 20px;
}

.box-model {
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.floating-left,
.grid .grid-item,
.grid .grid-item-right {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.floating-right {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}

.grid {
  margin-left: -20px;
}

.grid .grid-item,
.grid .grid-item-right {
  margin-right: 0;
  margin-left: 20px;
  margin-bottom: 0;
}

.grid .grid-item-right {
  float: right;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.b1 {
  width: 20px;
}

.b2 {
  width: 40px;
}

.b3 {
  width: 60px;
}

.b4 {
  width: 80px;
}

.b5 {
  width: 100px;
}

.b6 {
  width: 120px;
}

.b7 {
  width: 140px;
}

.b8 {
  width: 160px;
}

.b9 {
  width: 180px;
}

.b10 {
  width: 200px;
}

.b11 {
  width: 220px;
}

.b12 {
  width: 240px;
}

.b13 {
  width: 260px;
}

.b14 {
  width: 280px;
}

.b15 {
  width: 300px;
}

.b16 {
  width: 320px;
}

.b17 {
  width: 340px;
}

.b18 {
  width: 360px;
}

.b19 {
  width: 380px;
}

.b20 {
  width: 400px;
}

.b21 {
  width: 420px;
}

.b22 {
  width: 440px;
}

.b23 {
  width: 460px;
}

.b24 {
  width: 480px;
}

.b25 {
  width: 500px;
}

.b26 {
  width: 520px;
}

.b27 {
  width: 540px;
}

.b28 {
  width: 560px;
}

.b29 {
  width: 580px;
}

.b30 {
  width: 600px;
}

.b31 {
  width: 620px;
}

.b32 {
  width: 640px;
}

.b33 {
  width: 660px;
}

.b34 {
  width: 680px;
}

.b35 {
  width: 700px;
}

.b36 {
  width: 720px;
}

.b37 {
  width: 740px;
}

.b38 {
  width: 760px;
}

.b39 {
  width: 780px;
}

.b40 {
  width: 800px;
}

.b41 {
  width: 820px;
}

.b42 {
  width: 840px;
}

.b43 {
  width: 860px;
}

.b44 {
  width: 880px;
}

.b45 {
  width: 900px;
}

.b46 {
  width: 920px;
}

.b47 {
  width: 940px;
}

.b48 {
  width: 960px;
}

.b49 {
  width: 980px;
}

.b50 {
  width: 1000px;
}

.b51 {
  width: 1020px;
}

.b52 {
  width: 1040px;
}

.b53 {
  width: 1060px;
}

.b54 {
  width: 1080px;
}

.b55 {
  width: 1100px;
}

.b56 {
  width: 1120px;
}

.b57 {
  width: 1140px;
}

.b58 {
  width: 1160px;
}

.b59 {
  width: 1180px;
}

.b60 {
  width: 1200px;
}

.b61 {
  width: 1220px;
}

.b62 {
  width: 1240px;
}

.b63 {
  width: 1260px;
}

.b64 {
  width: 1280px;
}

.b65 {
  width: 1300px;
}

.b66 {
  width: 1320px;
}

.b67 {
  width: 1340px;
}

.b68 {
  width: 1360px;
}

.b69 {
  width: 1380px;
}

.b70 {
  width: 1400px;
}

.b71 {
  width: 1420px;
}

.b72 {
  width: 1440px;
}

.b73 {
  width: 1460px;
}

.b74 {
  width: 1480px;
}

.b75 {
  width: 1500px;
}

.b76 {
  width: 1520px;
}

.b77 {
  width: 1540px;
}

.b78 {
  width: 1560px;
}

.b79 {
  width: 1580px;
}

.b80 {
  width: 1600px;
}

.b81 {
  width: 1620px;
}

.b82 {
  width: 1640px;
}

.b83 {
  width: 1660px;
}

.b84 {
  width: 1680px;
}

.b85 {
  width: 1700px;
}

.b86 {
  width: 1720px;
}

.b87 {
  width: 1740px;
}

.b88 {
  width: 1760px;
}

.b89 {
  width: 1780px;
}

.b90 {
  width: 1800px;
}

.b91 {
  width: 1820px;
}

.b92 {
  width: 1840px;
}

.b93 {
  width: 1860px;
}

.b94 {
  width: 1880px;
}

.b95 {
  width: 1900px;
}

.b96 {
  width: 1920px;
}

.b97 {
  width: 1940px;
}

.b98 {
  width: 1960px;
}

.b99 {
  width: 1980px;
}

.b100 {
  width: 2000px;
}

.b101 {
  width: 2020px;
}

.b102 {
  width: 2040px;
}

.b103 {
  width: 2060px;
}

.b104 {
  width: 2080px;
}

.b105 {
  width: 2100px;
}

.b106 {
  width: 2120px;
}

.b107 {
  width: 2140px;
}

.b108 {
  width: 2160px;
}

.b109 {
  width: 2180px;
}

.b110 {
  width: 2200px;
}

.b111 {
  width: 2220px;
}

.b112 {
  width: 2240px;
}

.b113 {
  width: 2260px;
}

.b114 {
  width: 2280px;
}

.b115 {
  width: 2300px;
}

.b116 {
  width: 2320px;
}

.b117 {
  width: 2340px;
}

.b118 {
  width: 2360px;
}

.b119 {
  width: 2380px;
}

.b120 {
  width: 2400px;
}

.b121 {
  width: 2420px;
}

.b122 {
  width: 2440px;
}

.b123 {
  width: 2460px;
}

.b124 {
  width: 2480px;
}

.b125 {
  width: 2500px;
}

.b126 {
  width: 2520px;
}

.b127 {
  width: 2540px;
}

.b128 {
  width: 2560px;
}

[class^='icon-'],
[class*=' icon-'] {
  margin-right: 2px;
  width: 14px;
  height: 14px;
  vertical-align: baseline;
  display: inline-block;
  background-repeat: no-repeat;
  background: 0 0;
}

.no-js body div.js-warning {
  width: 100%;
  height: 100%;
  background: red;
  position: absolute;
  top: 0;
  left: 0;
}

.no-js body .wrapper {
  display: none;
}

.no-js body div.js-warning .wrapper {
  display: block;
  padding: 20px;
}

.invoice-badge-gray {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -480px;
  width: 120px;
  height: 120px;
}

.invoice-badge-green {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -120px;
  width: 120px;
  height: 120px;
}

.invoice-badge-red {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -240px;
  width: 120px;
  height: 120px;
}

.invoice-badge-test-mode {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -665px;
  width: 76px;
  height: 75px;
}

.invoice-badge-yellow {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -360px;
  width: 120px;
  height: 120px;
}

.invoice-boiler_plate_icon {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -772px;
  width: 32px;
  height: 32px;
}

.invoice-icon-ssl {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 80px;
  height: 80px;
}

.invoice-iugu_bank_slip_icon {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -740px;
  width: 32px;
  height: 32px;
}

.invoice-iugu_credit_card_icon {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -804px;
  width: 32px;
  height: 32px;
}

.invoice-logo-bradesco {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -80px;
  width: 40px;
  height: 40px;
}

.invoice-logo-qr-iugu {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -600px;
  width: 55px;
  height: 65px;
}

.invoice-toolbar-pay-bg {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -836px;
  width: 10px;
  height: 78px;
}

.relative {
  position: relative;
  min-height: 50px;
}

.use_this_cc {
  margin-top: 16px;
}

.use_this_cc .use_this_cc_label {
  margin-left: 25px;
}

.use_this_cc .use_this_cc_button {
  margin-top: 2px;
  position: absolute;
  border: 1px solid #000;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  text-align: center;
  line-height: 15px;
  color: #fff;
}

.use_this_cc .use_this_cc_button {
  background-color: #fff;
}

.use_this_cc .use_this_cc_button.marked {
  background-color: #3373ad;
}

.use_this_cc .use_this_cc_button.marked:hover {
  background-color: #4a8dc9;
}

.use_this_cc .use_this_cc_button:hover {
  cursor: pointer;
}

.use_this_cc .use_this_cc_button:hover {
  background-color: #f2f2f2;
  color: #f2f2f2;
}

.use_this_cc .checkbox {
  display: none;
}

#testmode-flag {
  background-image: url(/assets/invoice-sc8153d3cfd-153cfb97c169d9bdc5ba2e622e89f265.png);
  background-repeat: no-repeat;
  background-position: 0 -665px;
  width: 76px;
  height: 75px;
  position: absolute;
  width: 76px;
  height: 75px;
  top: 0;
  left: 0;
  z-index: 50;
}

#testmode-flag span {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  font-size: 9px;
  line-height: 9px;
  text-transform: uppercase;
  color: #fff;
  width: 90px;
  text-align: center;
  font-weight: 700;
  display: block;
  position: absolute;
  left: -15px;
  top: 28px;
}

.ios .rotated-badge span,
.android .rotated-badge span {
  width: 135px;
  right: -30px;
  top: 30px;
}

body {
  background-color: #fff;
  font-family: 'museo sans 100', helvetica;
  color: #202020;
}

body input,
body select {
  font-family: 'museo sans 100', helvetica;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: 'museo sans 100', helvetica;
  font-weight: 400;
}

body .invoice .header {
  background: #edeae3 !important;
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
  -webkit-print-color-adjust: exact;
}

body .invoice .header h6 {
  color: #7f7f7f;
  font-family: 'museo sans 300', helvetica;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}

body .invoice .header h5 {
  font-size: 14px;
  font-family: 'museo sans 500', helvetica;
}

body .invoice .header .client_name,
body .invoice .header .client_document,
body .invoice .header .client_address {
  margin-bottom: 15px;
}

body .invoice .header .client_name h5,
body .invoice .header .client_document h5,
body .invoice .header .client_address h5 {
  font-size: 13px;
}

body .invoice .header .account_name,
body .invoice .header .account_document,
body .invoice .header .account_address {
  font-family: 'museo sans 700';
  text-align: right;
  max-height: 70px;
  overflow: hidden;
  margin-bottom: 10px;
}

body .invoice .header .account_name span,
body .invoice .header .account_document span,
body .invoice .header .account_address span {
  display: block;
  font-size: 20px;
}

body .invoice .header .account_name img,
body .invoice .header .account_document img,
body .invoice .header .account_address img {
  margin: 0;
  padding: 0;
  max-height: 70px;
}

body .invoice .header .rotated-badge {
  position: absolute;
  right: 0;
  top: 0;
  width: 120px;
  height: 120px;
  overflow: hidden;
}

body .invoice .header .rotated-badge span {
  display: block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 32px;
  right: -22px;
  width: 130px;
  height: 20px;
  text-align: center;
  position: absolute;
  font-family: 'museo sans 700';
  font-size: 16px;
  line-height: 19px;
  filter: alpha(opacity=80);
  opacity: 0.8;
}

body .invoice .container {
  max-width: 680px;
}

body .invoice .wrapper {
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
}

body .invoice .wrapper .qr-code {
  margin-bottom: 10px;
}

body .invoice .wrapper .qr-code img {
  max-width: 100%;
  margin-bottom: 20px;
}

body .invoice .wrapper .qr-code h5,
body .invoice .wrapper .qr-code h6 {
  display: inline-block;
  font-family: 'museo sans 100';
  font-size: 10px;
  line-height: 12px;
  color: #a8a5a1;
}

body .invoice .wrapper .qr-code h6 {
  text-transform: uppercase;
  font-family: 'museo sans 300', helvetica;
}

body .invoice .wrapper .qr-code h5 {
  max-width: 60%;
  margin-left: 15px;
}

body .invoice .wrapper .invoice-details h3,
body .invoice .wrapper .invoice-details h5,
body .invoice .wrapper .invoice-details h6 {
  font-family: 'museo sans 300', helvetica;
  text-align: right;
  color: #202020;
}

body .invoice .wrapper .invoice-details h3 {
  font-size: 18px;
}

body .invoice .wrapper .invoice-details h6 {
  font-size: 14px;
  color: #7f7f7f;
}

body .invoice .wrapper .invoice-details h3.bigger {
  font-size: 22px;
  text-align: left;
}

body .invoice .wrapper .invoice-details .custom_table {
  width: 100%;
}

body .invoice .wrapper .invoice-details .custom_table th {
  font-weight: 400;
  color: #7f7f7f;
  padding: 0;
}

body .invoice .wrapper .invoice-details .custom_table td,
body .invoice .wrapper .invoice-details .custom_table th {
  padding-left: 0;
  padding-right: 0;
  font-size: 13px;
}

body .invoice .wrapper .invoice-details .custom_table td {
  padding-top: 5px;
  padding-bottom: 4px;
}

body .invoice .wrapper .invoice-details .custom_table td:not(:first-child),
body .invoice .wrapper .invoice-details .custom_table th:not(:first-child) {
  text-align: right;
}

body .invoice .wrapper .invoice-details .custom_table tr {
  border-bottom: 2px solid #edeae3;
}

body .invoice .wrapper .invoice-details .custom_table tr.additional-tr {
  border-bottom: 1px solid #edeae3;
}

body .invoice .wrapper .invoice-details .custom_table tr.total-tr {
  border-bottom: 0;
  margin-top: 10px;
  font-family: 'museo sans 500';
}

body .invoice .wrapper .invoice-details .custom_table tr.total-tr td {
  font-size: 18px;
  line-height: 40px;
  height: 40px;
  color: #202020;
}

body .invoice .wrapper h3 {
  font-family: 'museo sans 300', helvetica;
  margin-bottom: 15px;
  font-size: 22px;
}

body .invoice .wrapper p {
  font-family: 'museo sans 100', helvetica;
  color: #7f7f7f;
  font-size: 14px;
  margin-bottom: 15px;
}

body .invoice .wrapper h5 {
  font-family: 'museo sans 300', helvetica;
  font-size: 12px;
}

body .invoice .wrapper .invoice-icon-ssl {
  float: right;
}

#extra-info {
  padding-bottom: 5px;
}

.credit_card_form {
  position: relative;
}

.credit_card_form .form_header {
  position: relative;
  margin-bottom: 15px;
}

.credit_card_form .form_header img {
  position: absolute;
  top: 0;
  right: 0;
}

.credit_card_form .credit_card_name {
  text-transform: uppercase;
}

.credit_card_form input::-webkit-input-placeholder {
  text-transform: none;
}

.credit_card_form .pay-button {
  height: 44px;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  position: absolute;
  margin-top: 0;
  width: 140px;
  text-align: center;
  right: 0;
  top: 0;
  border: 0 !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  border-radius: 5px;
  -webkit-transition: 'all, 50ms, ease';
  transition: 'all, 50ms, ease';
  color: #fff;
  font-family: 'museo sans 500', helvetica;
  border: 0;
  text-shadow: 0 1px 0 #3373ad;
  background-color: #4a8dc9;
  box-shadow: 0 6px 0 #3373ad;
}

.credit_card_form .pay-button:focus {
  outline: 0;
}

.credit_card_form .pay-button:hover {
  top: 2px;
}

.credit_card_form .pay-button:active {
  top: 6px;
}

.credit_card_form .pay-button:hover {
  border: 0;
  background-color: #5e99cf !important;
  box-shadow: 0 4px 0 #3373ad;
}

.credit_card_form .pay-button:active {
  box-shadow: 0 3px 0 #3373ad;
}

.invoice .bank_slip_form {
  position: relative;
}

.invoice .bank_slip_form .form_header {
  position: relative;
  margin-bottom: 15px;
}

.invoice .bank_slip_form .form_header img {
  position: absolute;
  top: 5px;
  right: 0;
  max-width: 75px;
}

.invoice .bank_slip_form .bank_slip_info {
  position: relative;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_icon {
  position: absolute;
  top: 0;
  left: 0;
}

.invoice .bank_slip_form .bank_slip_info .right_bank_slip_info {
  padding-left: 0;
  margin-top: 2px;
}

.invoice
  .bank_slip_form
  .bank_slip_info
  .right_bank_slip_info
  .bank_slip_label {
  min-height: 34px;
  margin-bottom: 9px;
}

.invoice
  .bank_slip_form
  .bank_slip_info
  .right_bank_slip_info
  .bank_slip_label
  .last_label {
  margin-bottom: 0;
}

.invoice .bank_slip_form .bank_slip_info .transferor-line {
  margin-bottom: 9px;
}

.invoice .bank_slip_form .bank_slip_info .instruction_div {
  /* min-height: 47px; */
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label {
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 0 7px 7px;
  margin-bottom: 5px;
  padding-right: 0;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label .cpf_or_cnpj {
  padding-top: 2px;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label .cpf_or_cnpj span {
  color: #000;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label .address {
  padding-top: 2px;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label h5.after_due_date {
  font-size: 13px;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label span {
  font-family: 'museo sans 100', helvetica;
  font-size: 10px;
  line-height: 10px;
  margin: 0;
  padding: 0;
  display: block;
  text-transform: uppercase;
  color: #7f7f7f;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label.reverse span,
.invoice .bank_slip_form .bank_slip_info .bank_slip_label.reverse h5 {
  text-align: right;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label.icon_space {
  line-height: 10px;
  margin-left: 56px;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label .bank-info {
  padding-bottom: 2px;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label .bank-info h5,
.invoice .bank_slip_form .bank_slip_info .bank_slip_label .bank-info span {
  display: inline-block;
}

.invoice .bank_slip_form .bank_slip_info .bank_slip_label .bank-info span {
  font-size: 14px;
  margin-left: 8px;
  color: #7f7f7f;
}

.invoice .bank_slip_form .barcode_info h6,
.invoice .bank_slip_form .barcode_info h5 {
  font-family: 'museo sans 100';
  display: block;
  text-align: center;
  max-width: 405px;
}

.invoice .bank_slip_form .barcode_info h5 {
  font-family: 'museo sans 500';
}

.invoice .bank_slip_form .barcode_info h6 {
  font-size: 11px;
}

.invoice .bank_slip_form .barcode_info h5 {
  font-size: 10px;
}

.invoice .bank_slip_form .print-button,
.invoice .bank_slip_form .pay-bank-slip-button {
  height: 44px;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 0;
  width: 140px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  border: 0 !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  border-radius: 5px;
  -webkit-transition: 'all, 50ms, ease';
  transition: 'all, 50ms, ease';
  color: #fff;
  font-family: 'museo sans 500', helvetica;
  border: 0;
  text-shadow: 0 1px 0 #b8273c;
  background-color: #d63c53;
  box-shadow: 0 6px 0 #b8273c;
}

.invoice .bank_slip_form .print-button:focus,
.invoice .bank_slip_form .pay-bank-slip-button:focus {
  outline: 0;
}

.invoice .bank_slip_form .print-button:hover,
.invoice .bank_slip_form .pay-bank-slip-button:hover {
  top: 2px;
}

.invoice .bank_slip_form .print-button:active,
.invoice .bank_slip_form .pay-bank-slip-button:active {
  top: 6px;
}

.invoice .bank_slip_form .print-button:hover,
.invoice .bank_slip_form .pay-bank-slip-button:hover {
  border: 0;
  background-color: #da5166 !important;
  box-shadow: 0 4px 0 #b8273c;
}

.invoice .bank_slip_form .print-button:active,
.invoice .bank_slip_form .pay-bank-slip-button:active {
  box-shadow: 0 3px 0 #b8273c;
}

.invoice .bank_slip_form .pay-bank-slip-button {
  font-family: 'museo sans 500', helvetica;
  border: 0;
  text-shadow: 0 1px 0 #b3b3b3;
  background-color: #ccc;
  box-shadow: 0 6px 0 #b3b3b3;
}

.invoice .bank_slip_form .pay-bank-slip-button:hover {
  border: 0;
  background-color: #d9d9d9 !important;
  box-shadow: 0 4px 0 #b3b3b3;
}

.invoice .bank_slip_form .pay-bank-slip-button:active {
  box-shadow: 0 3px 0 #b3b3b3;
}

.printer {
  display: none;
}

@media print {
  .print-visible {
    display: block !important;
  }
  .printer {
    display: block;
  }
  .printer .reverse {
    text-align: right;
  }
  .printer .print-bs-header {
    margin-bottom: 20px;
  }
  .printer .print-bs-header .mechanical-aut {
    font-size: 8px;
  }
  .printer .bold {
    font-weight: 700;
  }
  .printer .cut-this-line span {
    font-size: 7px;
  }
  .printer .bank-slip-info {
    height: 350px;
    position: relative;
  }
  .printer .bank-slip-info .bank-slip-icon,
  .printer .bank-slip-info .iugu-icon {
    position: absolute;
    top: 0;
  }
  .printer .bank-slip-info .bank-slip-icon {
    left: 16px;
  }
  .printer .bank-slip-info .iugu-icon {
    right: 16px;
    width: 75px;
  }
  .printer .bank-slip-info .right-bank-slip-info {
    padding-left: 0;
    margin-top: 2px;
  }
  .printer .bank-slip-info .right-bank-slip-info .bank-slip-label {
    min-height: 33.5px;
    margin-bottom: 9px;
  }
  .printer .bank-slip-info .right-bank-slip-info .bank-slip-label .last-label {
    margin-bottom: 0;
  }
  .printer .bank-slip-info .bank-slip-label.no-space-left {
    margin-left: 0;
  }
  .printer .bank-slip-info .bank-slip-label.no-space-right {
    margin-right: 0;
  }
  .printer .bank-slip-info .no-padding-left {
    padding-left: 0;
  }
  .printer .bank-slip-info .bank-slip-label,
  .printer .bank-slip-info .bank-slip-aut-label {
    padding: 0 0 10px 10px;
    border-left: 1px solid #000;
    margin-bottom: 10px;
    margin-left: 54px;
  }
  .printer .bank-slip-info .bank-slip-label span,
  .printer .bank-slip-info .bank-slip-label h5,
  .printer .bank-slip-info .bank-slip-aut-label span,
  .printer .bank-slip-info .bank-slip-aut-label h5 {
    padding: 0;
    margin: 0;
  }
  .printer .bank-slip-info .bank-slip-label span,
  .printer .bank-slip-info .bank-slip-aut-label span {
    font-size: 8px;
  }
  .printer .bank-slip-info .bank-slip-label h5,
  .printer .bank-slip-info .bank-slip-aut-label h5 {
    font-size: 10px;
  }
  .printer .bank-slip-info .bank-slip-label span,
  .printer .bank-slip-info .bank-slip-label div,
  .printer .bank-slip-info .bank-slip-aut-label span,
  .printer .bank-slip-info .bank-slip-aut-label div {
    line-height: 10px;
    margin: 0;
    padding: 0;
    display: block;
  }
  .printer .bank-slip-info .bank-slip-label span,
  .printer .bank-slip-info .bank-slip-aut-label span {
    text-transform: uppercase;
    color: #686868 !important;
  }
  .printer .bank-slip-info .bank-slip-label .bank-info,
  .printer .bank-slip-info .bank-slip-aut-label .bank-info {
    margin-bottom: 0;
  }
  .printer .bank-slip-info .bank-slip-label .bank-info h5,
  .printer .bank-slip-info .bank-slip-label .bank-info span,
  .printer .bank-slip-info .bank-slip-aut-label .bank-info h5,
  .printer .bank-slip-info .bank-slip-aut-label .bank-info span {
    display: inline-block;
    font-size: 13px;
  }
  .printer .bank-slip-info .bank-slip-label .bank-info span,
  .printer .bank-slip-info .bank-slip-aut-label .bank-info span {
    margin-left: 8px;
  }
  .printer .bank-slip-info .bank-slip-label .line,
  .printer .bank-slip-info .bank-slip-aut-label .line {
    margin-bottom: 4px;
  }
  .printer .bank-slip-info .instruction-div {
  }
  .printer .bank-slip-info .bank-slip-label {
    border-bottom: 1px solid #000;
    /* min-height: 43px; */
  }
  .printer .bank-slip-info .bank-slip-label .cpf_or_cnpj {
    padding-top: 2px;
  }
  .printer .bank-slip-info .bank-slip-label .cpf_or_cnpj span {
    color: #000 !important;
  }
  .printer .bank-slip-info .bot-div .bank-slip-aut-label {
    border-top: 1px solid #000;
    position: absolute;
    width: 208px;
    height: 95px;
    bottom: -42px !important;
    right: 15px !important;
    -webkit-print-color-adjust: exact;
    padding-top: 5px;
    padding-left: 0;
    text-align: center;
    color: #686868 !important;
  }
  .printer .bank-slip-info .bot-div .barcode_info {
    margin-left: 15px;
  }
  .printer .bank-slip-info .bot-div .barcode_info .digitable-line {
    margin-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .invoice .credit_card_form .pay-button {
    width: 100%;
  }
  .invoice .bank_slip_form .print-button,
  .invoice .bank_slip_form .pay-bank-slip-button {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 30px;
  }
}

