/*
* Folha de estilos da tela de Login
*/

/*
* Container dos itens da tela de login, esta classe
* utiliza flex-box para alinhar os itens.
*/

.login-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  flex-direction: row;
  justify-content: space-around;
  max-height: 100vh;
  overflow: hidden;
  background: url("../../assets/login-bg.png");
  background-size: cover;
  background-position: left;
}

/*
* Imagem que fica do lado esquerdo da tela de login
*/

.login__logo {
  width: 500px;
  height: 200px;
  background: url("../../assets/logo-branca.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
}

.login__panel {
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

/*
* Container onde ficam os input fields da
* tela de login.
*/

.login__content {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px 0 60px;
  background-color: white;
}

.login__email {
  margin-bottom: 30px !important;
}

.login__button {
  width: 100px;
  align-self: center;
  margin-top: 20px !important;
}

.login__logo__mobile {
  display: none;
}

@media (max-width: 768px) {
  .login__panel {
    display: none;
  }

  .login__logo__mobile {
    display: initial;
    margin: 10px auto;
    width: 300px;
    height: 100px;
    background: url("./midia-logo.jpg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .login__content {
    padding: 10px;
    width: auto;
    background-color: transparent;
  }

  .login__content div::before,
  .login__content div::after {
    border-color: white !important;
  }

  .login__content label,
  .login__content svg,
  .login__content input {
    color: white !important;
  }

  .login__content button {
    background-color: white;
    color: yellowgreen;
  }
}
